/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {CartType} from '@wix/wixstores-client-core/dist/es/src/types/cart';
import webBiLogger from '@wix/web-bi-logger';
import {Logger} from '@wix/web-bi-logger/dist/src/types';
import {
  thankYouPageLoadSf,
  thankYouPageContinueBrowsingLinkClickedSf,
  thankYouPageClickOnLinkOnLineItem,
} from '@wix/bi-logger-ec-site/v2';

import {Specs} from '../../components/thankYouPage/constants';

export class BIService {
  private readonly siteStore: SiteStore;
  private readonly biLogger: Logger;
  private readonly shouldUsePlatformBILogger: boolean;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.biLogger = webBiLogger.factory().logger().updateDefaults({
      appName: 'Stores',
      msid: siteStore.msid,
    });
    this.shouldUsePlatformBILogger =
      siteStore.experiments.enabled(Specs.EnablePlatformBiLoggerTYP) &&
      siteStore.experiments.enabled(Specs.EnableNewTyp);
  }

  public thankYouPageContinueShoppingClickedSf({
    orderId,
    checkoutId,
    cartId,
  }: {
    orderId: string;
    checkoutId: string;
    cartId: string;
  }): void {
    if (this.shouldUsePlatformBILogger) {
      this.biLogger.report(
        thankYouPageContinueBrowsingLinkClickedSf({
          orderId,
          cartId,
          checkoutId,
        })
      );
    } else {
      this.siteStore.biLogger.thankYouPageContinueShoppingClickedSf({orderId});
    }
  }

  public thankYouPageClickOnLinkOnLineItemSf({
    cartId,
    checkoutId,
    catalogItemId,
    itemType,
    actionName,
  }: {
    cartId: string;
    checkoutId: string;
    catalogItemId: string;
    itemType: string;
    actionName: string;
  }): void {
    this.biLogger.report(
      thankYouPageClickOnLinkOnLineItem({
        cartId,
        checkoutId,
        catalogItemId,
        itemType,
        actionName,
      })
    );
  }

  public thankYouPageDownloadFileClickedSf(orderId: string): void {
    this.siteStore.biLogger.thankYouPageDownloadFileClickedSf({orderId});
  }

  public thankYouPageLoadSf({
    orderId,
    cartId,
    shippingMethodType,
    paymentMethodType,
    cartType,
    paymentStatus,
    paymentProvider,
    numOfLineItems,
    itemType,
    checkoutId,
  }: {
    orderId: string;
    cartId: string;
    shippingMethodType: string;
    paymentMethodType: string;
    cartType: CartType;
    paymentStatus: string;
    paymentProvider: string;
    numOfLineItems: number;
    itemType: string;
    checkoutId: string;
  }): void {
    let originForBI;
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    if (appSectionParams) {
      originForBI =
        JSON.parse(appSectionParams).origin === 'cart-cashier' ? 'cart directly to paypal fast flow' : undefined;

      // istanbul ignore if: this will be merged soon
      if (this.siteStore.experiments.enabled('specs.stores.ExpressCashierBiFix')) {
        originForBI = JSON.parse(appSectionParams).origin;
      }
    }

    if (this.shouldUsePlatformBILogger) {
      this.biLogger.report(
        thankYouPageLoadSf({
          orderId,
          cartId,
          cartType,
          paymentProvider,
          payment_status: paymentStatus,
          viewMode: this.siteStore.viewMode,
          origin: originForBI,
          numOfLineItems,
          itemType,
          checkoutId,
          paymentMethodType,
        })
      );
    } else {
      this.siteStore.biLogger.thankYouPageLoadSf({
        orderId,
        cartId,
        shippingMethodType,
        paymentMethodType,
        cartType,
        paymentProvider,
        payment_status: paymentStatus,
        viewMode: this.siteStore.viewMode,
        origin: originForBI,
      });
    }
  }
}
